
import React from "react";
import { Link } from "react-router-dom";
   
function Commands({ receiver, writer }) {
  return (


    <div className="command_container">
      
      <h1 style={{ 
          justifyContent: "center",
          alignItems: "center"
      }}>
        GatherBot Commands
      </h1>
        
      <p className="text-light" style={{ 
        //   width: "fit-content" 
        fontSize: "25px"
      }}>
        /today:
        Shows today's list of events
      </p>
      <p className="text-light" style={{ 
        //   width: "fit-content" 
        fontSize: "25px"
      }}>   
        /upcoming:
        Shows a list of upcoming events
      </p>
      <p className="text-light" style={{ 
        //   width: "fit-content" 
        fontSize: "25px"
      }}>
        /commands:
        Provides a url to a webpage of commands
      </p>
      <p className="text-light" style={{ 
        //   width: "fit-content" 
        fontSize: "25px"
      }}>
        /gatherbot:
        Description of what GatherBot is and what it can do
      </p>
      <br></br>
      <h2 className="author">Created by Christian Cardenas</h2>
      <br></br>
      <Link to="/" className='btn btn-primary' target='_blank'>Visit My Website</Link>
    </div>
  );
}
   
export default function App() {
  return (
    <div>
      <Commands writer="GFG" receiver="gfg jr" />
    </div>
  );
}