import React from 'react'
import './about.css'
import ME from '../../assets/skyline-shot.jpg'
import {FaAward} from 'react-icons/fa'
import {GiGraduateCap} from 'react-icons/gi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">  
          <div className="about__cards">
            <article className='about__card'>
                <FaAward className='about__icon'/>
                <h5>Experience</h5>
                <small>3+ Years Working As A Developer</small>
            </article>
            <article className='about__card'>
                <GiGraduateCap className='about__icon'/>
                <h5>B.S. Computer Science</h5>
                <small>University of Illinois at Chicago</small>
            </article>
            <article className='about__card'>
                <VscFolderLibrary className='about__icon'/>
                <h5>Projects</h5>
                <small>80+ Projects Completed Lifetime</small>
            </article>
          </div>

          <p>
          I have always been passionate about software engineering, that is largely driven by the ability that I could use what I have learned to create something that would benefit other people. I had the honor of working with people from different backgrounds such as science and business to help create software that would not only save time and make the work day more efficient, but also provide resources through my software that would benefit people's well-being. I take joy knowing that I could potentially help you out! 
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About