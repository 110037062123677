import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/survivle-gameplay-screenshot.png'
import IMG2 from '../../assets/groupme-gatherbot-screenshot.png'
import { Link } from "react-router-dom";

// const data = [
//   {
//     id: 1,
//     image: IMG1,
//     title: 'Survivle.app',
//     github: 'https://github.com/cardenasc33/survivle',
//     demo: 'https://youtube.com'
//   },
//   {
//     id: 2,
//     image: IMG2,
//     title: 'GatherBot',
//     github: 'https://github.com',
//     demo: 'https://youtube.com'
//   }
// ]

const Portfolio = () => {

  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
            <h3>Survivle.app <h6>Test your word solving skills while under time pressure</h6></h3>
            <div className="portfolio__item-cta">
              <a href="https://github.com/cardenasc33/survivle" className='btn' target='_blank'>Github</a>
              <a href="https://survivle.app" className='btn btn-primary' target='_blank'>Play</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
            <h3>GatherBot <h6>GroupMe bot that can retrieve event information from a Google Calendar</h6></h3>
            <div className="portfolio__item-cta">
              {/* <a href="https://www.christiancardenas.info" className='btn' target='_blank'>Commands</a> */}
              {/* <a onClick= {() => {navigate('/commands')}} className='btn' target='_blank'>Commands</a> */}
              <Link to="/GatherBot" className='btn' target='_blank'>Commands</Link>
              {/* <a href="https://youtube.com" className='btn btn-primary' target='_blank'>Watch Demo</a> */}
            </div>
            <a href="https://www.flaticon.com/free-icons/plant" title="plant icons" id="flaticon-attribute">Plant icons created by Freepik - Flaticon</a>
        </article>
      </div>
    </section>
  )
}

export default Portfolio 