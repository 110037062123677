import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {FaLinkedin} from 'react-icons/fa'
import {FaAngellist} from 'react-icons/fa'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import ME from '../../assets/profile-headshot.jpg'

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3r94psp', 'template_u4vch59', form.current, 'yk5w9DoX3DV6wViOx')
      
    e.target.reset();
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="contact_profile_headshot">
            <img src={ME} alt="Contact Image" />
      </div>
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>cardenasc33@gmail.com</h5>
            <a href="mailto:cardenasc33@gmail.com">Send a message</a>
          </article>
          <article className='contact__option'>
            <FaLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <a href="https://www.linkedin.com/in/christian-cardenas/" target="_blank">Visit Profile</a>
          </article>
          <article className='contact__option'>
            <FaAngellist className='contact__option-icon'/>
            <h4>AngelList Talent</h4>
            <a href="https://angel.co/u/christian-cardenas-1" target="_blank">Visit Profile</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form> */}
      </div>
    </section>
  )
}

export default Contact