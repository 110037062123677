import React from 'react'
import './header.css'
// import CTA from './CTA'
// import ME from '../../assets/profile-headshot-cropped.png'
import ME from '../../assets/profile-headshot.jpg'
import HeaderSocials from './HeaderSocials'
import About from '../about/About'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        {/* <h5>Hello I'm</h5> */}
        <h1>Christian Cardenas</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        {/* <CTA /> */}
        <HeaderSocials />
        <About/>

        {/* <div className="me">
          <img src={ME} alt="me" />
        
        </div> */}

        <a href="#contact" className='scroll__down'>Contact Info</a>
      </div>
    </header>
  )
}


export default Header