import React from 'react'
import './footer.css'
import {FaAngellist} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Christian Cardenas</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/christian-cardenas/" target="_blank"><FaLinkedin/></a>
        <a href="https://angel.co/u/christian-cardenas-1"target="_blank"><FaAngellist/></a>
        <a href="https://github.com/cardenasc33" target="_blank"><FaGithub/></a>
        {/* <a href="https://www.youtube.com/channel/UCacsWvwAPYE_8bo8qixpITA/featured"><FaYoutube/></a> */}
      </div>

      <div className="footer__copyright">
        <small>&copy; Christian Cardenas.  All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer