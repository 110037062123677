import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaAngellist} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/christian-cardenas/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/cardenasc33" target="_blank"><FaGithub/></a>
        <a href="https://angel.co/u/christian-cardenas-1" target="_blank"><FaAngellist/></a>
    </div>
  )
}

export default HeaderSocials