import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Commands from './components/GatherBot/Commands'

const App = () => {
  return (
    
    <>
        <Router>
          <Routes>
            <Route exact path="/" element={<Header />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<Nav />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<Experience />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<Portfolio />} />
            <Route exact path="/GatherBot" element={<Commands />} />
            {/* <Route exact path="/" element={<Services />} /> */}
            {/* <Route exact path="/" element={<Testimonials />} /> */}
          </Routes>
          <Routes>
            <Route exact path="/" element={<Contact />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<Footer />} />
          </Routes>
        </Router>
    </>
  )
}

export default App